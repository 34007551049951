import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export interface IMenuItem {
    id?: string;
    title?: string;
    description?: string;
    type: string;       // Possible values: link/dropDown/extLink
    name?: string;      // Used as display text for item and title for separator type
    state?: string;     // Router state
    icon?: string;      // Material icon name
    tooltip?: string;   // Tooltip text
    disabled?: boolean; // If true, item will not be appeared in sidenav.
    sub?: IChildItem[]; // Dropdown items
    badges?: IBadge[];
    active?: boolean;
    ADMIN?: boolean;
    SUPERVISOR?: boolean;
    ENGINEER?: boolean;
}
export interface IChildItem {
    id?: string;
    parentId?: string;
    type?: string;
    name: string;       // Display text
    state?: string;     // Router state
    icon?: string;
    sub?: IChildItem[];
    active?: boolean;
    ADMIN?: string;
    SUPERVISOR?: string;
    ENGINEER?: boolean;
}

interface IBadge {
    color: string;      // primary/accent/warn/hex color codes(#fff000)
    value: string;      // Display text
}

interface ISidebarState {
    sidenavOpen?: boolean;
    childnavOpen?: boolean;
}

@Injectable({
    providedIn: 'root'
})
export class NavigationService {
    public sidebarState: ISidebarState = {
        sidenavOpen: true,
        childnavOpen: false
    };
    selectedItem: IMenuItem;

    constructor() {
    }

    defaultMenu: IMenuItem[] = [
        {
            name: 'Energy',
            description: 'Energy',
            type: 'link',
            icon: './assets/icons/thunderbolt.png',
            state: '/energy',
            ADMIN: true,
            SUPERVISOR: true,
            ENGINEER: true
        },
        {
            name: 'Lighting',
            description: 'Lighting',
            type: 'link',
            icon: './assets/icons/lighting.png',
            state: '/lighting',
            ADMIN: true,
            SUPERVISOR: true,
            ENGINEER: true
        },
        {
            name: 'Air Condition',
            description: 'Air Condition',
            type: 'link',
            icon: './assets/icons/air-conditioner.png',
            state: '/air',
            ADMIN: true,
            SUPERVISOR: true,
            ENGINEER: true
        },
        {
            name: 'Generator',
            description: 'Generator',
            type: 'link',
            icon: './assets/icons/electric-generator.png',
            state: '/generator',
            ADMIN: true,
            SUPERVISOR: true,
            ENGINEER: true
        },

        {
            name: 'Fire Pump',
            description: 'Fire Pump',
            type: 'link',
            icon: './assets/icons/fire-pump.png',
            state: '/firepump',
            ADMIN: true,
            SUPERVISOR: true,
            ENGINEER: true
        },

        {
            name: 'Pool System',
            description: 'Pool System',
            type: 'link',
            icon: './assets/icons/pool.png',
            state: '/poolsystem',
            ADMIN: true,
            SUPERVISOR: true,
            ENGINEER: true
        },

        {
            name: 'Wast Water',
            description: 'Wast Water',
            type: 'link',
            icon: './assets/icons/waste-water.png',
            state: '/wastwater',
            ADMIN: true,
            SUPERVISOR: true,
            ENGINEER: true
        },


        {
            name: 'Sanitary',
            description: 'Sanitary ',
            type: 'link',
            icon: './assets/icons/sanitary.png',
            state: '/sanitary',
            ADMIN: true,
            SUPERVISOR: true,
            ENGINEER: true
        },
        /*
                        {
                            name: 'Ventilator',
                            description: 'Ventilator ',
                            type: 'link',
                            icon: './assets/icons/ventilator.png',
                            state: '/user7',
                            ADMIN: true,
                            SUPERVISOR: true,
                            ENGINEER: true
                        },*/
         {
            name: 'Report',
            description: 'Report ',
            type: 'link',
            icon: './assets/icons/history_report.png',
            state: '/report',
            ADMIN: true,
            SUPERVISOR: true,
            ENGINEER: true
        }, 
        {
            name: 'System Setting',
            description: 'System Setting ',
            type: 'link',
            icon: './assets/icons/setting.png',
            state: '/settings',
            ADMIN: true,
            SUPERVISOR: false,
            ENGINEER: false
        },
        {
            name: 'User Setting',
            description: 'User Setting ',
            type: 'link',
            icon: './assets/icons/setting.png',
            state: '/settings/user',
            ADMIN: true,
            SUPERVISOR: false,
            ENGINEER: false
        },



    ];


    // sets iconMenu as default;
    menuItems = new BehaviorSubject<IMenuItem[]>(this.defaultMenu);
    // navigation component has subscribed to this Observable
    menuItems$ = this.menuItems.asObservable();

    // You can customize this method to supply different menu for
    // different user type.
    // publishNavigationChange(menuType: string) {
    //   switch (userType) {
    //     case 'ADMIN':
    //       this.menuItems.next(this.ADMINMenu);
    //       break;
    //     case 'user':
    //       this.menuItems.next(this.userMenu);
    //       break;
    //     default:
    //       this.menuItems.next(this.defaultMenu);
    //   }
    // }
}
