<div class="app-footer">

    <div class="border-top  d-flex flex-column flex-sm-row ">
        <div class="d-flex text-center">
            <div>
                <br>
                <p  class="m-0">&copy; Copyright © Designed & Developed by iBMS Team 2020</p>

            </div>
        </div>
    </div>
</div>